import $ from 'jquery';
import {Page} from "./page";


export class Services extends Page {
    /**
     * The constructor
     *
     * @param container
     * @param navigation
     */
    constructor(container, navigation) {
        super(container, navigation); // appelle le constructeur parent avec le paramètre

        this.containerList = $('[data-nav-content="services-list"]').first();
        this.initialHeight = this.containerList.outerHeight();

        this.init();
    }

    /**
     * Initialise le service
     */
    init() {
        var self                  = this;
        var service               = $('.service');
        var serviceSection        = $('.section--services');
        var serviceNavigation     = $('.service__navigation'); // bouton du sous menu service
        var serviceDetail         = $('.service__detail');
        var activeSectionSelector = '.section--services.active';
        var serviceDetailSelector = '.section--services.active .service.active .service__detail';

        // Calcul de la hauteur de page au resize de la page service : nécessaire en plus du calcul de hauteur standard des pages, car à l'intérieur même des services plusieurs actions peuvent amener à modifier la hauteur du contenu

        $(window).on('resize', function () {
            if ($(window).width() < 1440) {
                self.closeNavigation();

                service.removeClass('active');
                serviceNavigation.removeClass('active');
                $('.page--services').children('.section:first-of-type').css('margin-top', '0');
                /*serviceSection
                    .removeClass('active')
                    .css('height', 'unset')
                ;*/
            }
        });

        /*serviceSection.find('[data-toggle-button-medium]').on('click', function() {
            setTimeout(function() {
                self.serviceDetailHeightCalculation(activeSectionSelector, serviceDetailSelector);
                self.navigation.pageHeightCalculation();
            }, 600);
        });*/
    }

    /**
     * Calculator de la hauteur du container
     */

    /*serviceDetailHeightCalculation() {
        var containerContentHeight = this.container.find('.service__detail').first().outerHeight()
        this.containerList.css('height', containerContentHeight);
    }

    closeHeightCalculation() {
        this.containerList.css('height', 2000);
    }*/

    /**
     * Ouvre un service
     *
     * @param serviceSectionSelector
     */
    displayNavigation() {
        this.containerList
            .addClass('active')
            .find('.service__navigation--right .title')
            .css({
                'animation-name': 'OpenServiceRightTitle',
                'animation-duration': '1s',
                'animation-fill-mode': 'forwards'
            })
        ;
    }

    display(button) {
        if (!this.hasParent()) {
            return this.displayContent();
        }

        if (button.data('nav-link-action') === 'close') {
            return this.closeServiceAndNavigation();
        }

        return this.displayService();
    }

    /**
     * Affiche le contenu d'un service
     *
     * @param thisButton
     * @param navLink
     */
    displayService() {
        var self         = this;
        // Comportement en affichage DESKTOP
        let parentObject = self.navigation.getPage("services");

        if (!parentObject.isActive()) {
            parentObject.display();
            setTimeout(function () {
                self.displayServiceContent();
            }, 1000);
        } else {
            var serviceSection = $('.section--services');
            if (serviceSection.hasClass('active')) {
                this.displayServiceContent();
            } else {
                var timing = 500;
                this.displayServiceContent(timing);
            }
        }
    }

    displayServiceContent(timing) {
        var self = this;
        this.container.addClass('active');
        var serviceSection = $('[data-nav-content="services-list"]').first();

        if ($(window).width() > 1440) {
            // Animation de retour à l'en-tête du service déployé
            var body = $("html, body");
            var activeServiceContent = $('.service.active .service__detail');
            var pageHeight = activeServiceContent.outerHeight();

            $('.page__wrapper').css('height', pageHeight + 192);

            $('.page--services').children('.section:first-of-type').css('margin-top', '-19rem');

            setTimeout(function() {
                body.animate({
                    scrollTop: $('[data-top-anchor]').offset().top
                });
            }, 500);

            // Si un service n'est pas déjà déployé
            if (!serviceSection.hasClass('active')) {
                // Si vue "Totem" > Déploiement du service cliqué
                self.displayNavigation();
            }

            setTimeout(function () {
                var buttonOffsetTop          = self.container.children('.service__navigation').offset().top;
                var servicesSectionOffsetTop = $('.section--services').offset().top;
                var activeButtonMargin       = buttonOffsetTop - servicesSectionOffsetTop;

                self.container
                    .find('.service__navigation')
                    .first()
                    .addClass('active')
                    .css('margin-top', -activeButtonMargin)
                ;

                /*self.serviceDetailHeightCalculation();
                self.navigation.pageHeightCalculation();*/
            }, timing);
        }
        /*else {
            setTimeout(function() {
                self.serviceDetailHeightCalculation();
                self.navigation.pageHeightCalculation();
            }, 600);
        }*/
    }

    closeService(event) {
        var self = this;

        if ($(window).width() > 1440) {
            $('.service').removeClass('active');
        }

        if ($(event.target).data('nav-link-action') && $(event.target).data('nav-link-action') === "close") {
            $('.page__wrapper').css('height', 'auto');

            setTimeout(function() {
                $('.page--services').children('.section:first-of-type').css('margin-top', '0');
            }, 250);

            setTimeout(function () {
                self.closeNavigation();
            }, 500);
        }

        // this.closeCalculation();
    }

    closeServiceAndNavigation() {
        var self = this;

        if ($(window).width() > 1440) {
            $('.service').removeClass('active');
            self.closeNavigation();
        }

        // this.closeCalculation();
    }

    closeNavigation() {
        this.containerList
            .removeClass('active')
            .find('.service__navigation--right .title')
            .css({
                'animation-name': 'CloseServiceRightTitle',
                'animation-duration': '.5s',
                'animation-fill-mode': 'forwards'
            })
        ;
    }

    /*closeCalculation()
    {
        var self = this;
        setTimeout(function() {
            self.closeHeightCalculation();
            self.navigation.pageHeightCalculation();
        }, 750);
    }*/

    /**
     * Reset l'affichage du service en cours
     */
    closeContent(currentPage, event) {
        var service      = $('.service');
        var mosaicButton = $('[data-mosaic-element-button]');

        // Au clic sur un élément de navigation on referme les vus détaillées des mosaïques
        // mosaicDetailClose(mosaicButton);

        // Au clic sur un élément de navigation on supprime les classes "active" sur l'ensemble des services et des liens
        service.removeClass('active');

        $('[data-nav-link-type="service"]').removeClass('active').css('margin-top', 0);
        if (currentPage === null) {
            return null;
        }

        if (currentPage.getType() === this.getType()) {
            this.closeService(event);
        } else {
            this.closeServiceAndNavigation();
        }
    }

    /**
     * Masque l'ensemble des services
     */
    closeServices() {
        setTimeout(function () {
            if (!$('.page--services').hasClass('active')) {
                $('.section--services')
                    .removeClass('active')
                    .css('height', 'initial')
                ;
                // $('.service.active [data-service-close]').click();
            }
        }, 750);
    }
}