import $ from 'jquery';
import {Services} from "./pages/services";
import {Page} from "./pages/page";

export class Navigation {
    constructor() {
        this.currentPage = {};
    }

    mobileNavClose() {
        this.mobileNavButton.removeClass('active').next('.navigation--mobile').removeClass('active');
        this.pageObjects = {};
    }

    init() {
        var self             = this;
        self.pageObjects     = {};
        self.pages           = $('[data-nav-content]');
        self.mobileNavButton = $('[data-nav-mobile]');
        self.navigationLink  = $('[data-nav-link]');

        // Mobile nav
        self.mobileNavButton.on('click', function () {
            $(this).toggleClass('active').next('.navigation--mobile').toggleClass('active');
        });
        $(window).on('load resize', function () {
            self.mobileNavClose();
        });

        // Pages navigation
        $(window).on('load', function (event) {
            var link = window.location.pathname.replace('/', '');

            self.initPage(link, self);
        });

        self.navigationLink.on('click', function (event) {
            var link = $(this).data('nav-link');

            self.initPage(link, self);
        });
    }

    initPage(link, self) {
        let previewCurrentPage = self.currentPage
        self.currentPage       = self.getPage(link);

        // Si page précédente ouverte, alors on ferme celle-ci avant d'ouvrir la suivante
        //
        if (previewCurrentPage && typeof previewCurrentPage['close'] === 'function') {
            previewCurrentPage.close(self.currentPage, event);

            setTimeout(function () {
                if (self.currentPage) {
                    self.currentPage.display($(this));
                    self.setBreadcrumbs(self.currentPage);
                }
            }, 500);
        } else {
            if (self.currentPage) {
                self.currentPage.display($(this));
                self.setBreadcrumbs(self.currentPage);
            }
        }
    }

    getPage(name) {
        // console.log('getPage');
        // console.log(name);
        // console.log(this.pageObjects);
        // if (this.pageObjects[name]) {
        //      return this.pageObjects[name];
        // }

        let containers = $(`[data-nav-content="${name}"]`);

        if (containers.length > 0) {
            let container  = containers.first();
            let pageObject = {};
            let typePage   = 'default';

            if (container.data('nav-type')) {
                typePage = container.data('nav-type');
            }
            switch (typePage) {
                case "service":
                case "close-service":
                    pageObject = new Services(container, this);
                    break;
                default:
                    pageObject = new Page(container, this);
                    break;
            }

            this.pageObjects[name] = pageObject;

            return pageObject;
        }

        return null;
    }

    displayPage() {
        var self = this;
        // Je supprime les classes active de toutes les pages
        this.pages.removeClass('active');

        setTimeout(function () {
            self.pages.not('.active').css('z-index', -1);
        }, 1000);
    }


    /**
     * Reset l'affichage des services
     */
    resetService() {
        var service = $('.service');
        // var mosaicButton = $('[data-mosaic-element-button]');

        // Au clic sur un élément de navigation on referme les vus détaillées des mosaïques
        // mosaicDetailClose(mosaicButton);

        // Au clic sur un élément de navigation on supprime les classes "active" sur l'ensemble des services et des liens
        service.removeClass('active');

        $('[data-nav-type="service"]').removeClass('active').css('margin-top', 0);
    }

    /**
     * Breadcrumbs
     */
    setBreadcrumbs(pageObject) {
        var navBreadcrumbs = pageObject.getBreadcrumbs();

        $('[data-box-breadcrumb]').html('<a href="#" class="breadcrumbs__link">' + navBreadcrumbs + '</a>');
    }

    /*getTiming() {
        var body = $("html, body");

        if (body.outerHeight(true) > $(window).outerHeight(true)) {
            return 250;
        }

        return 0;
    }*/

    /*pageHeightCalculation() {
        var pageWrapper      = $('.page__wrapper');
        var activePage       = $('.page.active');
        var activePageHeight = activePage.outerHeight();
        pageWrapper.css('height', activePageHeight);
    }*/
}
