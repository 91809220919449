import $ from 'jquery';
import Flickity from 'flickity/dist/flickity.pkgd';


$(document).ready(function () {
  if ($('.carousel').length > 0) {
    var carouselMain = new Flickity('.carousel', {
      cellAlign: 'left',
      contain: true,
      wrapAround: true,
      fullscreen: true,
      imagesLoaded: true,
      dragThreshold: 0,
      selectedAttraction: 0.05,
      friction: 0.8
    });
  }
});
