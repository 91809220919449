import $ from 'jquery';

var scrollTopButton = $('#scroll-top-button');

// Affichage du bouton de retour
$(window).on('scroll', function () {
    var scrollPos = $(window).scrollTop();

    scrollTopButton.removeClass('visible');

    if (scrollPos > 300) {
        scrollTopButton.addClass('visible');
    }
});

// Animation de retour haut de page
scrollTopButton.on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop:0}, '300');
});
