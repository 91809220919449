import $ from 'jquery';
import Isotope from 'isotope-layout/dist/isotope.pkgd';

export class Mosaic
{
    constructor() {
        this.selector = '.mosaic--masonry';
        this.isotopeGrid = null;
    }

    init($element) {
        let self = this;
        let $mosaics = $element.find(self.selector);
        if ($mosaics.length > 0) {
            self.isotopeGrid = new Isotope($mosaics[0], {
                itemSelector: '.mosaic--masonry > .mosaic__element',
                layoutMode: 'fitRows'
            });

           if ($element.find('#mission-filters').length > 0) {
               self.initFilters();
           }
        }
    }

    destroy() {
        if (self.isotopeGrid) {
            self.isotopeGrid.destroy();
        }
    }

    initFilters() {
        let self = this;
        // Isotope : bind filter button click
        $('#mission-filters').on('click', '.filter', function () {
            let filterValue = $(this).attr('data-filter');

            self.isotopeGrid.arrange({filter: filterValue});

            /*setTimeout(function() {
                pageHeightCalculation();
            }, 250);*/
        });

        // Isotope : change is-checked class on buttons
        $('.filter-group').each(function (i, buttonGroup) {
            let $buttonGroup = $(buttonGroup);

            $buttonGroup.on('click', '.filter', function () {
                $buttonGroup.find('.is-checked').removeClass('is-checked');
                $(this).addClass('is-checked');
            });
        });
    }
}

// Fonction de fermeture du détail des vignettes
/*export function mosaicDetailClose(objectSelector) {
    // Suppression des classes active et reset de la hauteur
    objectSelector
        .removeClass('active')
        .next('.mission').removeClass('open')
        .css('height', '0')
        .parent('.mosaic__element').removeClass('active')
    ;

    // Reload du layout Isotope après traitement
    setTimeout(function() {
        isotopeGrid.layout();
    }, 500);
}*/

// var body         = $("html, body");
// var mosaicButton = $('[data-mosaic-element-button]');

// Gestion de l'ouverture/fermeture des vignettes
/*mosaicButton.on('click', function() {
    var thisButton           = $(this);
    var thisElement          = thisButton.parent();
    var activeElement        = thisButton.parents('.mosaic').children('.active');
    var thisMosaic           = thisElement.parent().children();
    var thisElementIndex     = thisMosaic.index(thisElement);
    var activeElementIndex   = thisMosaic.index(activeElement);
    var missionContentHeight = thisButton.next('.mission').children('.mission__content').outerHeight() + 8;

    if ($(window).width() > 1440) {
        // Ajout des classes active et calcul de la hauteur du contenu déployé
        thisButton
            .toggleClass('active')
            .next('.mission').toggleClass('open')
            .css('height', missionContentHeight)
            .parent('.mosaic__element').toggleClass('active')
        ;

        // Reset de toutes les autres vignettes : suppression des classes et reset de la hauteur
        mosaicDetailClose(mosaicButton.not(thisButton));

        // Comportement mosaïque : Si on referme une vignette déjà ouverte
        if (!thisButton.hasClass('active')) {
            thisButton.next('.mission').css('height', '0');

            setTimeout(function () {
                body.animate({
                    scrollTop: thisButton.offset().top - 300
                }, 1000);
            }, 250);
        } else {
            // Comportement mosaïque : Si on ouvre une vignette différente de celle déjà ouverte
            if (activeElement.length > 0) {
                // Si l'élément cliqué se trouve après l'élément déjà ouvert
                if (thisElementIndex > activeElementIndex) {
                    setTimeout(function () {
                        body.animate({
                            scrollTop: thisButton.offset().top + 50
                        }, 500);
                    }, 700);
                } else {
                    // Si l'élément cliqué se trouve avant l'élément déjà ouvert
                    setTimeout(function () {
                        body.animate({
                            scrollTop: thisButton.offset().top + 50
                        }, 500);
                    }, 150);
                }
            } else {
                // Comportement mosaïque : Si on ouvre une vignette alors qu'aucune n'est ouverte
                body.animate({
                    scrollTop: thisButton.offset().top + 50
                }, 500);
            }
        }

        // Reload du layout Isotope après traitement
        setTimeout(function () {
            isotopeGrid.layout();
        }, 300);
    }
});*/

// Bouton de fermeture du détail des vignettes
/*$('[data-mosaic-detail-close]').on('click', function() {
    var thisMosaicElement = $(this).parents('.mosaic__element');
    var thisMosaicButton  = thisMosaicElement.find('[data-mosaic-element-button]');

    mosaicDetailClose(thisMosaicButton);

    setTimeout(function () {
        body.animate({
            scrollTop: thisMosaicButton.offset().top - 300
        }, 1000);
    }, 250);
});*/

// Recalcul de la hauteur de page après avoir cliqué sur une vignette de mosaïque (DESKTOP)
/*$('.mosaic--missions > .mosaic__element').on('click', function() {
    var activeSectionSelector = '.section--services.active';
    var serviceDetailSelector = '.section--services.active .service.active .service__detail';

    if ($(window).width() > 1440) {
        setTimeout(function() {
            serviceDetailHeightCalculation(activeSectionSelector, serviceDetailSelector);
            pageHeightCalculation();
        }, 500);
    }
});*/

// Affichage SMALL/MEDIUM du détail des mosaïques

/*mosaicButton.on('click', function() {
    if ($(window).outerWidth() < 1440) {
        $(this).next('.mosaic__detail').toggle('fast');
    }
});*/
