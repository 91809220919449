import $ from 'jquery';

export class Page {
    constructor(container, navigation) {
        this.navigation = navigation;
        this.container  = container;
        this.pages      = $('[data-nav-content]');
        this.name       = this.container.data('nav-content');
        this.type       = this.container.data('nav-type');
        this.parentLink = this.container.data('nav-parent');
        console.log('construct Page', this.name, 'type', this.type, 'Parent', this.parentLink);
    }

    getType() {
        return this.type;
    }

    hasParent() {
        return this.parentLink != null;
    }

    getBreadcrumbs() {
        return this.container.data('nav-breadcrumbs');
    }

    isActive() {
        return this.container.hasClass('active');
    }

    display() {
        this.displayContent();
    }

    displayContent() {
        /*var self = this;
        var body = $("html, body");

        // Je supprime les classe "active" des menus principaux
        $('[data-nav-link-type="default"]').removeClass('active');

        var timing = this.navigation.getTiming();

        body.animate({scrollTop: 0}, timing, function () {
            self.navigation.displayPage();

            // J'ajoute la classe active sur la page concernée
            self.container
                .addClass('active')
                .css('z-index', '0')
            ;

            // Je recalcule la hauteur de page
            // self.navigation.pageHeightCalculation();

            // Je ferme la nav mobile
            self.navigation.mobileNavClose();
        });

        // J'ajoute la classe active sur l'élément du menu correspondant
        $('[data-nav-link="' + this.name + '"]').addClass('active');*/
    }

    close(currentPage, event) {
        return this.closeContent(currentPage, event);
    }

    closeContent() {

    }
}

