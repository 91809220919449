import $ from 'jquery';
import {Mosaic} from "./mosaic";

function toggleSection(size, toggleButton, thisButtonSelector) {
    let thisButton        = thisButtonSelector;
    // Boutons : Variantes selon taille d'écran
    let dataToggleButton  = thisButton.data('toggle'+size+'');
    // Paramétrage de la variable de section selon le type de bouton (small, medium ou default)
    let thisToggleSection = $('[data-toggle-section'+size+'="'+dataToggleButton+'"]');

    // Statut du bouton
    toggleButton.not(thisButton).removeClass('open');
    thisButton.toggleClass('open');

    // Fermeture de toutes les autres sections éventuellement ouvertes
    $("[data-toggle-section"+size+"]").not(thisToggleSection).removeClass('open').slideUp('250');

    // Déploiement s'il ne s'agit pas de la section déjà ouverte
    thisToggleSection.toggleClass('open').slideToggle('250');
    if (thisToggleSection.hasClass('open')) {
        setTimeout(function() {
            let mosaic = new Mosaic();
            mosaic.init(thisToggleSection);
        }, 300);
    }

    setTimeout(function() {
        let body = $("html, body");

        body.animate({
            scrollTop: thisButton.offset().top - 100
        }, 500);
    }, 400);
}

let buttonToggleLarge   = $('[data-toggle-large]');
let buttonToggleMedium  = $('[data-toggle-medium]');
let buttonToggleSmall   = $('[data-toggle-small]');
let sectionToggleLarge  = $('[data-toggle-section-large]');
let sectionToggleMedium = $('[data-toggle-section-medium]');
let sectionToggleSmall  = $('[data-toggle-section-small]');

// Reset
/*$(window).on('resize', function() {
    buttonToggleLarge.removeClass('open');
    buttonToggleMedium.removeClass('open');
    buttonToggleSmall.removeClass('open');

    sectionToggleLarge.removeClass('open').css('display', 'none');

    if ($(window).outerWidth() > 1440) {
        sectionToggleMedium.css('display', 'flex');
        sectionToggleSmall.css('display', 'flex');
    }

    if ($(window).outerWidth() < 1440) {
        sectionToggleMedium.removeClass('open').css('display', 'none');
    }

    if ($(window).outerWidth() > 800) {
        sectionToggleSmall.css('display', 'flex');
    }

    if ($(window).outerWidth() < 800) {
        sectionToggleSmall.removeClass('open').css('display', 'none');
    }
});*/

buttonToggleLarge.on('click', function(e) {
    e.preventDefault();

    let size = '-large';
    let thisButton = $(this);

    toggleSection(size, $('[data-toggle-large]'), thisButton);
});

buttonToggleMedium.on('click', function(e) {
    e.preventDefault();

    if ($(window).outerWidth() < 1440) {
        let size = '-medium';
        let thisButton = $(this);

        toggleSection(size, buttonToggleMedium, thisButton);
    }
});

buttonToggleSmall.on('click', function(e) {
    e.preventDefault();

    if ($(window).outerWidth() < 800) {
        let size = '-small';
        let thisButton = $(this);

        toggleSection(size, $('[data-toggle-small]', thisButton));
    }
});

