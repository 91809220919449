// import '../../../node_modules/flickity-fullscreen/fullscreen';
// import '../../../node_modules/flickity/dist/flickity.pkgd';
import $ from 'jquery/dist/jquery';
import './components/content';
import './components/scroll-top';
import './components/mosaic';
import './components/services';
import './components/navigation';
import './components/section';
import './components/carousel';
import {Navigation} from "./components/navigation";
import {Missions} from "./components/pages/missions";

let navigation = new Navigation();

navigation.init();

let $mission = $('#missions');
if ($mission.length > 0) {
    new Missions($mission, null);
}