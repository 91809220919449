import {Page} from "./page";
import {Mosaic} from "../mosaic";

export class Missions extends Page {
    constructor(container, navigation) {
        super(container, navigation);
        let mosaic = new Mosaic();
        mosaic.init(container);
    }
}
    /**
     * Reset l'affichage du service en cours
     */
    /*closeContent() {
        if ($(window).width() > 1440) {
            // Fermeture d'une éventuelle "mission" déployée dans la mosaïque du service actif
            $('[data-mosaic-element-button]')
                .removeClass('active')
                .next('.mission').removeClass('open')
                .css('height', '0')
            ;
        }

        // Recalcul de la hauteur de page
        setTimeout(function() {
            self.navigation.pageHeightCalculation();
        }, 1000);
    }*/
