import $ from 'jquery';

// // Fonction de calcul de la hauteur de page (utilisé en affichage desktop uniquement)
/*export function serviceDetailHeightCalculation($container, $content) {
    var container              = $($container);
    var containerContentHeight = $($content).outerHeight();

    container.css('height', containerContentHeight);
}*/


export class Services {
    init() {
        // var self = this;
        var service           = $('.service');
        var serviceSection    = $('.section--services');
        var serviceNavigation = $('.service__navigation');
        var serviceDetail     = $('.service__detail');
        // var activeSectionSelector = '.section--services.active';
        // var serviceDetailSelector = '.section--services.active .service.active .service__detail';

        // Calcul de la hauteur de page au resize de la page service : nécessaire en plus du calcul de hauteur standard des pages, car à l'intérieur même des services plusieurs actions peuvent amener à modifier la hauteur du contenu
        $(window).on('resize', function () {
            if ($(window).width() > 1440) {
                serviceDetail.removeClass('open');

                /*self.serviceDetailHeightCalculation(activeSectionSelector, serviceDetailSelector);
                pageHeightCalculation();*/
            } else {
                service.removeClass('active');
                serviceNavigation.removeClass('active');
                serviceSection
                    .removeClass('active')
                    .css('height', 'unset')
                ;
            }
        });

        /*serviceSection.find('[data-toggle-button-medium]').on('click', function() {
            setTimeout(function() {
                self.serviceDetailHeightCalculation(activeSectionSelector, serviceDetailSelector);
                pageHeightCalculation();
            }, 600);
        });*/
    }

    /*serviceDetailHeightCalculation($container, $content) {
        var container              = $($container);
        var containerContentHeight = $($content).outerHeight();

        container.css('height', containerContentHeight);
    }*/

    openService(serviceSectionSelector) {
        serviceSectionSelector
            .addClass('active')
            .find('.service__navigation--right .title')
            .css({
                'animation-name': 'OpenServiceRightTitle',
                'animation-duration': '1s',
                'animation-fill-mode': 'forwards'
            })
        ;
    }

    resetService() {
        var service = $('.service');
        // var mosaicButton = $('[data-mosaic-element-button]');

        // Au clic sur un élément de navigation on referme les vus détaillées des mosaïques
        // mosaicDetailClose(mosaicButton);

        // Au clic sur un élément de navigation on supprime les classes "active" sur l'ensemble des services et des liens
        service.removeClass('active');

        $('[data-nav-type="service"]').removeClass('active').css('margin-top', 0);
    }

    showContentService(thisButton, navLink) {
        var self           = this;
        var serviceSection = $('.section--services');
        // var activeSectionSelector = '.section--services.active';
        // var serviceDetailSelector = '.section--services.active .service.active .service__detail';

        // Ajout de la classes "active" sur le service sélectionné
        thisButton.parents('.service').addClass('active');

        // Comportement en affichage DESKTOP
        if (navLink) {
            var page = $('.page--services');

            if (!page.is('.active')) {
                // Pour accéder à un service depuis une autre page
                $('[data-nav-link="services"]').click();

                setTimeout(function () {
                    $('[data-nav-link="' + navLink + '"]').click();
                }, 1000);
            } else {
                if ($(window).width() > 1440) {
                    // Animation de retour à l'en-tête du service déployé
                    var body = $("html, body");

                    body.animate({
                        scrollTop: $('[data-top-anchor]').offset().top
                    });

                    // Si un service est déjà déployé
                    if (serviceSection.is('.active')) {
                        setTimeout(function () {
                            var dataService              = $("[data-nav-content=" + navLink + "]");
                            var buttonOffsetTop          = dataService.children('.service__navigation').offset().top;
                            var servicesSectionOffsetTop = $('.section--services').offset().top;
                            var activeButtonMargin       = buttonOffsetTop - servicesSectionOffsetTop;

                            dataService
                                .children('.service__navigation')
                                .addClass('active')
                                .css('margin-top', -activeButtonMargin)
                            ;

                            /*serviceDetailHeightCalculation(activeSectionSelector, serviceDetailSelector);
                            pageHeightCalculation();*/
                        }, 500);
                    } else {
                        // Si vue "Totem" > Déploiement du service cliqué
                        self.openService(serviceSection);

                        setTimeout(function () {
                            thisButton.click();
                        }, 250);
                    }
                }
            }
        }
    }

    hideContentService() {
        var service        = $('.service');
        var serviceSection = $('.section--services');
        // var activeSectionSelector = '.section--services.active';
        // var serviceDetailSelector = '.section--services.active .service.active .service__detail';

        if ($(window).width() > 1440) {
            // Fermeture d'une éventuelle "mission" déployée dans la mosaïque du service actif
            /*$('[data-mosaic-element-button]')
                .removeClass('active')
                .next('.mission').removeClass('open')
                .css('height', '0')
            ;*/

            // Retour à l'affichage "Totem"
            setTimeout(function () {
                // Suppression des classes "active"
                serviceSection.removeClass('active');
                service.removeClass('active');

                // Animation de fermeture pour les éléments en affichage "droite"
                $('.service__navigation--right .title').css({
                    'animation-name': 'CloseServiceRightTitle',
                    'animation-duration': '.5s',
                    'animation-fill-mode': 'forwards'
                });
            }, 250);

            // Recalcul de la hauteur de page
            /*setTimeout(function() {
                serviceDetailHeightCalculation(activeSectionSelector, serviceDetailSelector);
                pageHeightCalculation();
            }, 1000);*/
        }
    }

}

