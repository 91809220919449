import $ from 'jquery';

// Fonction de calcul de la hauteur de page (utilisé en affichage desktop uniquement)
/*
export function pageHeightCalculation() {
    var pageWrapper      = $('.page__wrapper');
    var activePage       = $('.page.active');
    var activePageHeight = activePage.outerHeight();

    pageWrapper.css('height', activePageHeight);
}
*/

// Initialisation de la home page
var page = $('.page');

$(window).on('load', function() {
    setTimeout(function() {
        page.addClass('active').css('z-index', '0');
    }, 250)
});

$('[data-nav-type="default"]').on('click', function() {
    page.removeClass('active');
});

// Calcul de la hauteur de page active au chargement, ou au resize
/*$(window).on('load resize', function() {
    pageHeightCalculation();
});*/
